import { render } from "./Forms.vue?vue&type=template&id=128f91b1"
import script from "./Forms.vue?vue&type=script&lang=js"
export * from "./Forms.vue?vue&type=script&lang=js"

import "./Forms.vue?vue&type=style&index=0&id=128f91b1&lang=scss"
script.render = render

export default script
import QField from 'quasar/src/components/field/QField.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTime from 'quasar/src/components/time/QTime.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QField,QList,QItem,QItemSection,QCheckbox,QItemLabel,QInput,QIcon,QSelect,QRadio,QPopupProxy,QDate,QBtn,QTime});qInstall(script, 'directives', {Ripple,ClosePopup});
