<template>
  <div
    :id="spreadSheetName.toLowerCase()"
    class="forms full-width row wrap justify-center items-center content-start q-pa-none q-ma-none q-px-sm"
    style="min-height: calc(100vh)"
  >
    <div
      class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
      style=""
    >
      <interstellar-text
        :id="spreadSheetName"
        class="text-h6 q-ma-none q-pa-none"
        @click="test"
      >
        {{ spreadSheetName }}
      </interstellar-text>
    </div>
    <template v-for="item in spreadSheetMetaData" :key="item">
      <div
        class="col-12 justify-center items-center content-center q-ma-none q-pa-none q-mb-md"
        style=""
      ></div>
      <div
        class="full-width row wrap justify-center items-center content-center"
      >
        <div
          class="col-12 justify-center items-center content-center q-ma-none q-pa-none"
          style=""
        >
          <span
            class="text-subtitle2 text-bold bg-primary text-white q-pa-xs rounded-borders shadow-1"
          >
            {{ item.Name }}
          </span>
          <!-- CHECKBOX -->
          <div v-if="item.ItemType === 'CHECKBOX'">
            <q-field
              :ref="
                (el) => {
                  dataRef[item.Name] = el;
                }
              "
              v-model="dataForms[item.Name]"
              class="bg-transparent"
            >
              <template v-slot:control>
                <q-list dense class="full-width q-ma-none q-pa-none">
                  <!--
                    Rendering a <label> tag (notice tag="label")
                    so QCheckboxes will respond to clicks on QItems to
                    change Toggle state.
                  -->
                  <template
                    v-for="itemOption in dataFilterOptions[item.Name]"
                    :key="itemOption"
                  >
                    <q-item tag="label" v-ripple>
                      <q-item-section avatar>
                        <q-checkbox
                          v-model="dataForms[item.Name]"
                          :val="itemOption"
                          color="primary"
                        ></q-checkbox>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ itemOption }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </template>
            </q-field>
            <q-input
              v-if="dataFormsOtherIsVisible[item.Name]"
              v-model="dataFormsOther[item.Name]"
              outlined
              color="primary"
              label="Otros:"
              label-color="primary"
              class="bg-transparent q-mb-lg q-mt-none q-pt-none"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-input>
          </div>
          <!-- LIST -->
          <div v-else-if="item.ItemType === 'LIST'">
            <q-select
              :ref="
                (el) => {
                  dataRef[item.Name] = el;
                }
              "
              v-model="dataForms[item.Name]"
              :options="dataFilterOptions[item.Name]"
              :mask="''"
              fill-mask
              use-input
              use-chips
              input-debounce="0"
              @filter="filterFn"
              @focus="inputFocusName = item.Name"
              outlined
              color="primary"
              label-color="primary"
              class="bg-transparent"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-select>
            <q-input
              v-if="dataFormsOtherIsVisible[item.Name]"
              v-model="dataFormsOther[item.Name]"
              outlined
              color="primary"
              label="Otros:"
              label-color="primary"
              class="bg-transparent q-mb-lg q-mt-none q-pt-none"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-input>
          </div>
          <!-- LIST_MULTIPLE_SELECT -->
          <div v-else-if="item.ItemType === 'LIST_MULTIPLE_SELECT'">
            <q-select
              :ref="
                (el) => {
                  dataRef[item.Name] = el;
                }
              "
              v-model="dataForms[item.Name]"
              :options="dataFilterOptions[item.Name]"
              :mask="''"
              fill-mask
              use-input
              use-chips
              input-debounce="0"
              @filter="filterFn"
              @focus="inputFocusName = item.Name"
              multiple
              outlined
              color="primary"
              label-color="primary"
              class="bg-transparent"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-select>
            <q-input
              v-if="dataFormsOtherIsVisible[item.Name]"
              v-model="dataFormsOther[item.Name]"
              outlined
              color="primary"
              label="Otros:"
              label-color="primary"
              class="bg-transparent q-mb-lg q-mt-none q-pt-none"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-input>
          </div>
          <!-- MULTIPLE_CHOICE -->
          <div v-if="item.ItemType === 'MULTIPLE_CHOICE'">
            <q-field
              :ref="
                (el) => {
                  dataRef[item.Name] = el;
                }
              "
              v-model="dataForms[item.Name]"
              :rules="[(val) => val.length > 0 || 'Selecciona al menos uno']"
              class="bg-transparent"
            >
              <template v-slot:control>
                <q-list dense class="full-width q-ma-none q-pa-none">
                  <!--
                    Rendering a <label> tag (notice tag="label")
                    so QCheckboxes will respond to clicks on QItems to
                    change Toggle state.
                  -->
                  <template
                    v-for="itemOption in dataFilterOptions[item.Name]"
                    :key="itemOption"
                  >
                    <q-item tag="label" v-ripple>
                      <q-item-section avatar>
                        <q-radio
                          v-model="dataForms[item.Name]"
                          :val="itemOption"
                          color="primary"
                        ></q-radio>
                      </q-item-section>
                      <q-item-section>
                        <q-item-label>{{ itemOption }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </template>
                </q-list>
              </template>
            </q-field>
            <q-input
              v-if="dataFormsOtherIsVisible[item.Name]"
              v-model="dataFormsOther[item.Name]"
              outlined
              color="primary"
              label="Otros:"
              label-color="primary"
              class="bg-transparent q-mb-lg q-mt-none q-pt-none"
            >
              <template v-slot:prepend>
                <q-icon name="edit" color="primary"></q-icon>
              </template>
            </q-input>
          </div>
          <!-- PARAGRAPH_TEXT -->
          <q-input
            :ref="
              (el) => {
                dataRef[item.Name] = el;
              }
            "
            v-else-if="item.ItemType === 'PARAGRAPH_TEXT'"
            v-model="dataForms[item.Name]"
            :mask="item.Mask ? item.Mask : ''"
            fill-mask
            :rules="[
              (val) => dataRules[item.Name].test(val) || 'Formato invalido',
            ]"
            type="textarea"
            outlined
            color="primary"
            label-color="primary"
            class="bg-transparent"
          >
            <template v-slot:prepend>
              <q-icon name="edit" color="primary"></q-icon>
            </template>
          </q-input>
          <!-- TEXT -->
          <q-input
            :ref="
              (el) => {
                dataRef[item.Name] = el;
              }
            "
            v-else-if="item.ItemType === 'TEXT'"
            v-model="dataForms[item.Name]"
            :mask="item.Mask ? item.Mask : ''"
            fill-mask
            :rules="[
              (val) => dataRules[item.Name].test(val) || 'Formato invalido',
            ]"
            outlined
            color="primary"
            label-color="primary"
            class="bg-transparent"
          >
            <template v-slot:prepend>
              <q-icon name="edit" color="primary"></q-icon>
            </template>
          </q-input>
          <!-- TIMESTAMP -->
          <q-input
            :ref="
              (el) => {
                dataRef[item.Name] = el;
              }
            "
            v-else-if="item.ItemType === 'TIMESTAMP'"
            v-model="dataForms[item.Name]"
            :mask="item.Mask ? item.Mask : ''"
            fill-mask
            :rules="[
              (val) => dataRules[item.Name].test(val) || 'Formato invalido',
            ]"
            outlined
            color="primary"
            label-color="primary"
            class="bg-transparent"
            :disable="item.KeyType === 'lastUpdateKey'"
          >
            <template v-slot:prepend>
              <q-icon name="edit" color="primary"></q-icon>
            </template>
            <template v-slot:after v-if="item.KeyType !== 'lastUpdateKey'">
              <q-icon
                name="event"
                class="cursor-pointer q-pr-xs"
                color="primary"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <!-- <q-date
                    v-model="dataForms[item.Name]"
                    mask="YYYY-MM-DD HH:mm"
                  > -->
                  <q-date
                    v-model="dataForms[item.Name]"
                    mask="YYYY-MM-DD HH:mm:ss.SSS"
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      ></q-btn>
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
              <q-icon
                name="access_time"
                class="cursor-pointer q-pr-sm"
                color="primary"
              >
                <q-popup-proxy transition-show="scale" transition-hide="scale">
                  <!-- <q-time
                    v-model="dataForms[item.Name]"
                    mask="YYYY-MM-DD HH:mm"
                    format24h
                  > -->
                  <q-time
                    v-model="dataForms[item.Name]"
                    mask="YYYY-MM-DD HH:mm:ss.SSS"
                    format24h
                    with-seconds
                  >
                    <div class="row items-center justify-end">
                      <q-btn
                        v-close-popup
                        label="Close"
                        color="primary"
                        flat
                      ></q-btn>
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
    </template>
  </div>
</template>

<style></style>

<script>
import {
  defineAsyncComponent,
  ref,
  watch,
  // computed,
  onMounted,
  nextTick,
} from "vue";

export default {
  name: "forms",
  components: {
    InterstellarText: defineAsyncComponent(() =>
      import("@/components/common/InterstellarText.vue")
    ),
  },
  props: {
    spreadSheetName: {
      type: String,
      default: "Forms",
    },
    spreadSheetMetaData: {
      type: Array,
      default: () => [],
    },
    selectedRows: {
      type: Array,
      default: () => [],
    },
    formMethod: {
      type: String,
      default: "create",
    },
  },
  emits: ["getNewData"],
  setup(props, { emit }) {
    console.log("Forms ready");
    window.moment.locale("es-mx");
    // console.log(JSON.stringify(props.spreadSheetMetaData, null, "\t"));
    // console.log(JSON.stringify(props.selectedRows[0], null, "\t"));
    const dataForms = ref({});
    const dataFormsOther = ref({});
    const dataFormsOtherIsVisible = ref({});
    function fillDataForms() {
      props.spreadSheetMetaData.forEach((item) => {
        // console.log(item.Name);
        let defaultDataValue = null;
        let itemOptions = item["ItemOptions"].split("|");
        switch (item.ItemType) {
          case "CHECKBOX":
            // if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
            //   // console.log(props.selectedRows[0][item.Name]);
            //   defaultDataValue = props.selectedRows[0][item.Name].split("|");
            // } else {
            //   defaultDataValue = [];
            // }
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = props.selectedRows[0][item.Name].split("|");
              // console.log(JSON.stringify(item, null, "\t"));
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                if (Array.isArray(defaultDataValue)) {
                  for (let i = 0; i < defaultDataValue.length; i++) {
                    if (itemOptions.indexOf(defaultDataValue[i]) < 0) {
                      dataFormsOther.value[item.Name] = defaultDataValue[i];
                      defaultDataValue[i] = "Otros:";
                      dataFormsOtherIsVisible.value[item.Name] = true;
                      break;
                    }
                  }
                } else {
                  if (itemOptions.indexOf(defaultDataValue) < 0) {
                    dataFormsOther.value[item.Name] = defaultDataValue;
                    defaultDataValue = "Otros:";
                    dataFormsOtherIsVisible.value[item.Name] = true;
                  }
                }
              }
            } else {
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                dataFormsOther.value[item.Name] = "";
              }
              defaultDataValue = [];
            }
            break;
          case "LIST":
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = props.selectedRows[0][item.Name].split("|");
              // console.log(JSON.stringify(item, null, "\t"));
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                if (Array.isArray(defaultDataValue)) {
                  for (let i = 0; i < defaultDataValue.length; i++) {
                    if (itemOptions.indexOf(defaultDataValue[i]) < 0) {
                      dataFormsOther.value[item.Name] = defaultDataValue[i];
                      defaultDataValue[i] = "Otros:";
                      dataFormsOtherIsVisible.value[item.Name] = true;
                      break;
                    }
                  }
                } else {
                  if (itemOptions.indexOf(defaultDataValue) < 0) {
                    dataFormsOther.value[item.Name] = defaultDataValue;
                    defaultDataValue = "Otros:";
                    dataFormsOtherIsVisible.value[item.Name] = true;
                  }
                }
              }
            } else {
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                dataFormsOther.value[item.Name] = "";
              }
              defaultDataValue = null;
            }
            break;
          case "LIST_MULTIPLE_SELECT":
            // if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
            //   // console.log(props.selectedRows[0][item.Name]);
            //   defaultDataValue = props.selectedRows[0][item.Name].split("|");
            // } else {
            //   defaultDataValue = [];
            // }
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = props.selectedRows[0][item.Name].split("|");
              // console.log(JSON.stringify(item, null, "\t"));
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                if (Array.isArray(defaultDataValue)) {
                  for (let i = 0; i < defaultDataValue.length; i++) {
                    if (itemOptions.indexOf(defaultDataValue[i]) < 0) {
                      dataFormsOther.value[item.Name] = defaultDataValue[i];
                      defaultDataValue[i] = "Otros:";
                      dataFormsOtherIsVisible.value[item.Name] = true;
                      break;
                    }
                  }
                } else {
                  if (itemOptions.indexOf(defaultDataValue) < 0) {
                    dataFormsOther.value[item.Name] = defaultDataValue;
                    defaultDataValue = "Otros:";
                    dataFormsOtherIsVisible.value[item.Name] = true;
                  }
                }
              }
            } else {
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                dataFormsOther.value[item.Name] = "";
              }
              defaultDataValue = [];
            }
            break;
          case "MULTIPLE_CHOICE":
            // if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
            //   // console.log(props.selectedRows[0][item.Name]);
            //   defaultDataValue = props.selectedRows[0][item.Name];
            // } else {
            //   defaultDataValue = "";
            // }
            // console.log(JSON.stringify(props.selectedRows[0][item.Name]));
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              // defaultDataValue = props.selectedRows[0][item.Name].split("|");
              defaultDataValue = props.selectedRows[0][item.Name];
              // console.log(JSON.stringify(item, null, "\t"));
              // console.log("defaultDataValue: " + JSON.stringify(defaultDataValue));
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                if (Array.isArray(defaultDataValue)) {
                  // console.log("defaultDataValue: " + JSON.stringify(defaultDataValue));
                  for (let i = 0; i < defaultDataValue.length; i++) {
                    if (itemOptions.indexOf(defaultDataValue[i]) < 0) {
                      dataFormsOther.value[item.Name] = defaultDataValue[i];
                      defaultDataValue[i] = "Otros:";
                      dataFormsOtherIsVisible.value[item.Name] = true;
                      break;
                    }
                  }
                } else {
                  if (itemOptions.indexOf(defaultDataValue) < 0) {
                    // console.log("defaultDataValue: " + JSON.stringify(defaultDataValue));
                    dataFormsOther.value[item.Name] = defaultDataValue;
                    defaultDataValue = "Otros:";
                    dataFormsOtherIsVisible.value[item.Name] = true;
                  }
                }
              }
            } else {
              if (itemOptions.indexOf("Otros:") > -1) {
                dataFormsOtherIsVisible.value[item.Name] = false;
                dataFormsOther.value[item.Name] = "";
              }
              defaultDataValue = "";
            }
            break;
          case "PARAGRAPH_TEXT":
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = props.selectedRows[0][item.Name];
            } else {
              defaultDataValue = "";
            }
            break;
          case "TEXT":
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = props.selectedRows[0][item.Name];
            } else {
              defaultDataValue = "";
            }
            break;
          case "TIMESTAMP":
            window.moment.locale("es-mx");
            if (props.selectedRows[0] && props.selectedRows[0][item.Name]) {
              // console.log(props.selectedRows[0][item.Name]);
              defaultDataValue = window
                .moment(
                  props.selectedRows[0][item.Name],
                  "YYYY-MM-DDTHH:mm:ss.SSSZ"
                )
                .format("YYYY-MM-DD HH:mm:ss.SSS");
            } else {
              defaultDataValue = window
                .moment()
                .format("YYYY-MM-DD HH:mm:ss.SSS");
            }
            break;
          default:
            defaultDataValue = "";
            break;
        }
        dataForms.value[item.Name] = defaultDataValue;
      });
    }

    const dataRef = ref({});
    function fillDataRef() {
      props.spreadSheetMetaData.forEach((item) => {
        dataRef.value[item.Name] = ref(null);
      });
    }

    const inputFocusName = ref("");
    const dataOptions = ref({});
    const dataFilterOptions = ref({});
    function filldataFilterOptions() {
      props.spreadSheetMetaData.forEach((item) => {
        if (
          item.ItemType === "CHECKBOX" ||
          item.ItemType === "LIST" ||
          item.ItemType === "LIST_MULTIPLE_SELECT" ||
          item.ItemType === "MULTIPLE_CHOICE"
        ) {
          dataOptions.value[item.Name] = item.ItemOptions.split("|");
          dataFilterOptions.value[item.Name] = item.ItemOptions.split("|");
        }
      });
    }

    const dataRules = ref({});
    function fillDataRules() {
      props.spreadSheetMetaData.forEach((item) => {
        // dataRules.value[item.Name] = item.Rules
        //   ? new RegExp(item.Rules)
        //   : new RegExp(".*");
        // console.log(item.Rules);
        // console.log(typeof item.Rules);
        dataRules.value[item.Name] = new RegExp(item.Rules);
        // console.log(typeof dataRules.value[item.Name]);
        // console.log(dataRules.value[item.Name]);
      });
    }
    // const isDataValid = ref([]);
    async function validateData() {
      await nextTick();
      let isDataValidTemp = [];
      // console.log(JSON.stringify(Object.keys(dataRef.value), null, "\t"));
      Object.keys(dataRef.value).forEach((item) => {
        if (typeof dataRef.value[item].validate === "function") {
          // console.log(dataRef.value[item].validate());
          isDataValidTemp.push(dataRef.value[item].validate());
        }
      });
      // console.log(
      //   JSON.stringify(Object.keys(dataFormsOtherIsVisible.value), null, "\t")
      // );
      Object.keys(dataFormsOtherIsVisible.value).forEach((item) => {
        // console.log(JSON.stringify(dataForms.value[item], null, "\t"));
        let dataToCheck = Array.isArray(dataForms.value[item])
          ? dataForms.value[item]
          : [dataForms.value[item]];
        // console.log(JSON.stringify(dataToCheck));
        if (dataToCheck.indexOf("Otros:") > -1) {
          dataFormsOtherIsVisible.value[item] = true;
        } else {
          dataFormsOtherIsVisible.value[item] = false;
          dataFormsOther.value[item] = "";
        }
      });
      // isDataValid.value = isDataValidTemp;
      let newData = {
        data: JSON.parse(JSON.stringify(dataForms.value)),
        dataOther: JSON.parse(JSON.stringify(dataFormsOther.value)),
        isDataValid: isDataValidTemp,
        formMethod: props.formMethod,
      };
      // console.log("fromForms: " + JSON.stringify(newData, null, "\t"));
      // console.log(JSON.stringify(Object.values(newData.data), null));
      emit("getNewData", newData);
    }
    // const testRef = ref(null);
    fillDataRef();
    fillDataForms();
    filldataFilterOptions();
    fillDataRules();
    onMounted(() => {
      // fillDataRules();
      validateData();
    });
    // LIST && LIST_MULTIPLE_SELECT
    // function createValue(val, done, stringOptions) {
    //   if (val.length > 2) {
    //     if (!stringOptions.includes(val)) {
    //       done(val, "add-unique");
    //     }
    //   }
    // }
    // function filterFn(val, update, filterOptions, stringOptions) {
    //   update(() => {
    //     if (val === "") {
    //       filterOptions.value = stringOptions;
    //     } else {
    //       const needle = val.toLowerCase();
    //       filterOptions.value = stringOptions.filter(
    //         (v) => v.toLowerCase().indexOf(needle) > -1
    //       );
    //     }
    //   });
    // }
    watch(
      () => dataForms,
      () => {
        // console.log("watch: " + JSON.stringify(dataForms.value));
        // console.log("watch: " + JSON.stringify(dataRef.value));
        // dataRef.value.forEach((item) => {
        //   console.log(item.validate());
        // });
        // Object.keys(dataRef.value).forEach((item) => {
        //   console.log(item);
        // });
        validateData();
      },
      { deep: true }
    );
    watch(
      () => dataFormsOther,
      () => {
        // let newData = {
        //   data: JSON.parse(JSON.stringify(dataForms.value)),
        //   dataOther: JSON.parse(JSON.stringify(dataFormsOther.value)),
        //   isDataValid: isDataValidTemp,
        //   formMethod: props.formMethod,
        // };
        // console.log("fromForms: " + JSON.stringify(newData, null, "\t"));
        // // console.log(JSON.stringify(Object.values(newData.data), null));
        // emit("getNewData", newData);
        validateData();
      },
      { deep: true }
    );
    return {
      dataForms,
      dataFormsOther,
      dataFormsOtherIsVisible,
      dataRules,
      dataRef,
      inputFocusName,
      dataFilterOptions,
      test() {
        console.log("test");
      },
      createValue(val, done) {
        if (val.length > 2) {
          if (!dataOptions.value[inputFocusName.value].includes(val)) {
            done(val, "add-unique");
          }
        }
      },
      filterFn(val, update) {
        // console.log("val: " + val);
        // console.log("update: " + update);
        update(() => {
          if (val === "") {
            dataFilterOptions.value[inputFocusName.value] =
              dataOptions.value[inputFocusName.value];
          } else {
            const needle = val.toLowerCase();
            dataFilterOptions.value[inputFocusName.value] = dataOptions.value[
              inputFocusName.value
            ].filter((v) => v.toLowerCase().indexOf(needle) > -1);
          }
        });
      },
    };
  },
};
</script>

<style lang="scss">
.bg-transparent {
  background: rgba(255, 255, 255, 0) !important;
}
// .q-field__inner .relative-position .col {
//   background: rgba(0, 27, 88, 0.7) !important;
//   border-radius: 10px;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
// }
// .q-field--outlined .q-field__control {
//   border-radius: 10px !important;
//   background: rgba(0, 27, 88, 0.7) !important;
// }
// .q-field__control {
//   border-radius: 10px !important;
//   background: rgba(0, 27, 88, 0.7) !important;
// }
.q-field__inner {
  background: rgba(255, 255, 255, 0.5) !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}

.q-field__bottom {
  background: rgba(255, 255, 255, 0) !important;
}
</style>
